import { HashConnect, HashConnectTypes, MessageTypes } from "hashconnect";
import { HashConnectConnectionState } from "hashconnect/dist/types";
import React, { useCallback, useEffect, useState, Component } from "react";
import { TransferTransaction } from '@hashgraph/sdk';
import { useCookies } from "react-cookie";
import { HASHPACKCONFIG } from '../configs/index';


const hashconnect = new HashConnect();
const mainWallet = HASHPACKCONFIG.depositWallet;
const raffleWallet = HASHPACKCONFIG.raffleWallet;
const appMetadata: HashConnectTypes.AppMetadata = {
    name: "Hashino Casino",
    description: "An example hedera dApp",
    icon: "https://www.hashpack.app/img/logo.svg",
  };

  export interface ProviderProps {
    children: React.ReactNode;
    network: "testnet" | "mainnet" | "previewnet";
    metaData?: HashConnectTypes.AppMetadata;
    debug?: boolean;
  }
  
  export interface HashconnectContextAPI {
    availableExtension: HashConnectTypes.WalletMetadata;
    state: HashConnectConnectionState;
    topic: string;
    pairingString: string;
    pairingData: any | null;
  }
  
  
  export const HashconectServiceContext = React.createContext<
    Partial<
      HashconnectContextAPI & {
        pairString?:string,
        network: "testnet" | "mainnet" | "previewnet";
        setState: React.Dispatch<React.SetStateAction<Partial<HashconnectContextAPI>>>;
      }
    >
  >({});

  export const HashconnectAPIProvider = ({ children, metaData, network, debug }: ProviderProps) => {
    const [state, setState] = React.useState<Partial<HashconnectContextAPI>>({});

    const initHashconnect = async () => {
        //initialize and use returned data
        let initData = await hashconnect.init(metaData ?? appMetadata, network);
        let privateKey = initData.privKey;
        console.log(privateKey);

        let s = await hashconnect.connect();
        let topic = s.topic;

        let pairString = hashconnect.generatePairingString(s, HASHPACKCONFIG.network, false);
        console.log(pairString);
        //Saved pairings will return here, generally you will only have one unless you are doing something advanced
        //hashconnect.findLocalWallets();
        //hashconnect.connectToLocalWallet(pairString);
        console.log(hashconnect);
        let pairingData:any = null;

        setState((exState) => ({ ...exState, topic, pairingData, pairString, state: HashConnectConnectionState.Disconnected }));
      };
    
      const onFoundExtension = (data: HashConnectTypes.WalletMetadata) => {
        console.log("Found extension", data);
        setState((exState) => ({ ...exState, availableExtension: data }));
      };
    
      const onParingEvent = (data: MessageTypes.ApprovePairing) => {
        console.log("Paired with wallet", data);
        setState((exState) => ({ ...exState, pairingData: data }));
      };
    
      const onConnectionChange = (state: HashConnectConnectionState) => {
        console.log("hashconnect state change event", state);
        setState((exState) => ({ ...exState, state }));
      };

      const onTransasction = (d:any) => {
        console.log(d);
      }
    
      //register events
      React.useEffect(() => {
        hashconnect.foundExtensionEvent.on(onFoundExtension);
        hashconnect.pairingEvent.on(onParingEvent);
        hashconnect.connectionStatusChange.on(onConnectionChange);
        return () => {
          hashconnect.foundExtensionEvent.off(onFoundExtension);
          hashconnect.pairingEvent.on(onParingEvent);
          hashconnect.connectionStatusChange.off(onConnectionChange);
          hashconnect.transactionEvent.on(onTransasction);
        };
      }, []);
    
      //Call Initialization
      React.useEffect(() => {
        initHashconnect();
      }, []);
    
      return <HashconectServiceContext.Provider value={{ ...state, setState, network }}>{children}</HashconectServiceContext.Provider>;


  };


  export const useHashconnectService = () => {
    console.log('connecting using service');
    const value = React.useContext(HashconectServiceContext);
    const { topic, pairingData,pairString, network, setState } = value;
    const connectToExtension = async () => {
      let state = await hashconnect.connect();
        let topic = state.topic;
      if(pairString){
        console.log(hashconnect.foundExtensionEvent);
        console.log(state);
        console.log(pairString);

        let local = hashconnect.findLocalWallets();

        hashconnect.connectToIframeParent(pairString);
        hashconnect.connectToLocalWallet(pairString);
      }
      //this will automatically pop up a pairing request in the HashPack extension
      
    };

    const sendTransaction = async (amount:number, token:any, walletStr:string = 'hot') => {
      

      if(network && topic && pairingData ){
        if(pairingData.accountIds){
          let accountId = pairingData.accountIds[0];        
          let provider = hashconnect.getProvider(network, topic, accountId);
          let signer = hashconnect.getSigner(provider);

          //toggle wallet to separate raffle funds with coinflip hot wallet
          let wallet = mainWallet;
          if(walletStr == 'raffle'){
            wallet = raffleWallet;
          }

          let transaction = await new TransferTransaction();
          
          if(token.symbol == 'HBAR'){
            transaction.addHbarTransfer(accountId, -amount)
            .addHbarTransfer(wallet, amount)
            .freezeWithSigner(signer);
          }else{
            transaction.addTokenTransfer(token.account, accountId, -amount)
            .addTokenTransfer(token.account, wallet, amount)
            .freezeWithSigner(signer);
          }

          
              

          let res = await transaction.executeWithSigner(signer)
          return res;
        }
        
      }     
    };


    
  
    const disconnect = () => {
      //hashconnect.disconnect(pairingData?.topic!);
      setState!((exState) => ({ ...exState, pairingData: null }))!;
    };
  
    const requestAccountInfo = async () => {
      const request: MessageTypes.AdditionalAccountRequest = {
        topic: topic!,
        network: network!,
        multiAccount: true,
      };
  
      await hashconnect.requestAdditionalAccounts(topic!, request);
    };
  
    const clearPairings = () => {
      //hashconnect.clearConnectionsAndData();
      setState!((exState) => ({ ...exState, pairingData: null }));
    };
  
    return { ...value, connectToExtension, sendTransaction, disconnect, requestAccountInfo, clearPairings };
  };



