import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import { Button, Typography } from "@mui/material";
const GambleAwareBanner = () => {

    return (
        <>
        <Box
        sx={{background:'#F06D23', color:'#fff', py:10, px:'16px'}}>
            <Container>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={12} md={8} lg={8}>
                        
                        <Typography variant="h3" gutterBottom>
                            Be Gamble Aware
                        </Typography>

                        <Typography gutterBottom>
                            If you're worried about your own or someone else's gambling and require help and support about problem gambling please contact the National Gambling Helpline on 0808 8020 133 (UK) or via their website
                        </Typography>

                        <Button 
                            variant="contained"
                            size="large"
                            sx={{mt:3}}
                            onClick={() => {
                                window.open('https://www.begambleaware.org/contact-us', "_blank", "noreferrer");
                            }}
                        >Get Help & Support</Button>
                    </Grid>
                </Grid>
                
            </Container>
        </Box>

            
        </>
    );
};


export default GambleAwareBanner;