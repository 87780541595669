import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import './styles.scss';

const HashinoCard = (props:{title:string,description:string, icon:any, minHeight:string}) => {
    return (
        <>
            <Paper elevation={2} className='hashino-card'>
                <Box className='hashino-card__inner' sx={{minHeight:props.minHeight}}>
                    <Box className="hashino-card__inner__icon">{props.icon}</Box>
                    <Typography variant='h6' sx={{color:'#d4af37', fontFamily:"'Krona One', sans-serif", mt:2, mb:1}}>{props.title}</Typography>
                    <Typography variant='caption' sx={{color:'#fff', pb:2}}>{props.description}</Typography>
                    <Box className='hashino-card__footer'></Box>
                </Box>
                
            </Paper>
        </>
    );
}

export default HashinoCard;