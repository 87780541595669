import { HASHPACKCONFIG } from "../configs";

const ProcessGame = async (data:FormData) => {
    try {
        const response = await fetch(HASHPACKCONFIG.hashcasinoUrl+'wp-json/hashcasino/v1/submit-bet', {
          method: 'POST',
          body: data,
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        return await response.json();
        
      } catch (error) {
        console.error(error);
      }
};

const GetRaffles = async () => {
  try {
      const response = await fetch(HASHPACKCONFIG.hashcasinoUrl+'wp-json/hashcasino/v1/get-raffles', {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
      
    } catch (error) {
      console.error(error);
    }
};

const SubmitRaffleEntry = async (data:any) => {
  try {
    const response = await fetch(HASHPACKCONFIG.hashcasinoUrl+'wp-json/hashcasino/v1/submit-raffle-entry', {
      method: 'POST',
      body: data,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
    
  } catch (error) {
    console.error(error);
  }
}


export {
    ProcessGame,
    GetRaffles,
    SubmitRaffleEntry
};