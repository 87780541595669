const HASHPACKCONFIG = {
    version:0.1,
    network:'mainnet',
    depositWallet: '0.0.3836341',
    raffleWallet: '0.0.3982177',
    //hashcasinoUrl:'http://hashcasino.local/',
    hashcasinoUrl:'https://api.hashino.casino/'
    
};

const HASHINOCONFIG:any = {
    cryptos:{
        SAUCEINU:{
            symbol:'SAUCEINU',
            account:'0.0.2964435',
            icon:'/images/sauceinu.png',
            decimals:7,
            theme:{
                bg:'#000',
                primary:'#6AFF52',
                fontColour:'#fff'
            }
        },
        SAUCE:{
            symbol:'SAUCE',
            account:'0.0.731861',
            icon:'/images/sauce.svg',
            decimals:6,
            theme:{
                bg:'#000',
                primary:'#6AFF52',
                fontColour:'#fff'
            }
        },
        HBAR:{
            symbol:'HBAR',
            account:'0.0.1456986',
            icon:'/images/hbar.svg',
            decimals:0,
            theme:{
                bg:'#dedede',
                primary:'#111',
                fontColour:'#111'
            }
        }
    }
}



export {
    HASHPACKCONFIG,
    HASHINOCONFIG
}