import React, {useContext, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './styles.scss';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { IconButton, List, ListItem } from '@mui/material';
import {HASHINOCONFIG} from '../../../configs/index';
import Avatar from '@mui/material/Avatar';
import { HashconectServiceContext, useHashconnectService } from '../../../services/hashConnect';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { SubmitRaffleEntry } from '../../../services/Api';
import AddComma from '../../../utilities/AddComma';
import { LocalActivity } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import RaffleAnimation from './RaffleAnimation';
import CloseIcon from '@mui/icons-material/Close';
interface RaffleCardProps {
    gameId:number,
    gameConfig:GameConfig
}

interface GameConfig {
    players:number,
    limit:number,
    bet:number,
    fee:number,
    lastWinner:string,
    entrants:Array<any>,
    currency:string,
    highlight:boolean
}
const MAX_RETRIES = 5;
const RETRY_DELAY_MS = 5000; // 5 seconds
const RaffleCard = (props:RaffleCardProps) => {
    
    const [retryCount, setRetryCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [submitData, setFormData] = useState<any>();
    const [entrants, setEntrants] = useState<Array<any>>([]);
    const [snackbarMessage, setSnackbarMessage] = useState<{severity:'error' | 'info' | 'success' | 'warning', message:string }>({'severity':'error', 'message':'Failed to enter raffle'});
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false)
    };
    const { pairingData, availableExtension, network, pairingString } = useContext(HashconectServiceContext);
    const [processing, setProcessing] = useState(false);
    const hashconnect = useHashconnectService();
    const crypto:any = HASHINOCONFIG.cryptos[props.gameConfig.currency];
   

    
    const [gameState, setGameState] = React.useState(0);
    const States = [
        'Idle',
        'Waiting on response from Hashpack',
        'Verifying Transaction',
        'Retrying attempt: ',
        'Raffle entry complete'
    ];



    let style = {
      color:'#1a1a1a'
    };


    let percentage = 0;
    if(props.gameConfig.players && props.gameConfig.limit){
        percentage =  (100 * entrants.length) / props.gameConfig.limit
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setSnackbarOpen(true);
      };
    
      const handleAlertClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackbarOpen(false);
      };
    const addDecimals = (inputNumber: number, numberOfZeros: number): number => {
        const numberAsString: string = inputNumber.toString();
        const stringWithZeros: string = numberAsString + '0'.repeat(numberOfZeros);
        return parseInt(stringWithZeros, 10);
    }
    


    const enterRaffle = async () => {
        setProcessing(true);
        hashconnect.sendTransaction(addDecimals(props.gameConfig.bet, crypto.decimals), crypto, 'raffle').then((transaction:any) => {
            console.log(transaction);
            if(typeof transaction !== 'undefined'){
              console.info("transaction accepted");

              let data:any = {
                raffleID:props.gameId,
                bet: props.gameConfig.bet,
                symbol:crypto.symbol,
                transactionHash:transaction.transactionHash,
                transactionId:transaction.transactionId,
                nodeId:transaction.nodeId
              };

              setFormData(data);
              let fd = new FormData();
          

              for ( var key in data ) {
                fd.append(key, data[key]);
              }


              SubmitRaffleEntry(fd).then((d) => {
                if(d.paymentRecived){
                  confirmBet(d);
                }else{
                  console.log("calling reset! "+ retryCount)
                  let ret = retryCount;
                  ret++;
                  setRetryCount(ret);
                  
                }
              })


            }else{
                setSnackbarMessage({'severity':'error','message':'Transaction Failed'});
                showAlert();
                setProcessing(false);
            }
        });
    };
    
    const confirmBet = (data:any) => {
      setSnackbarMessage({'severity':'success','message':'Raffle Entered, Good Luck!'});
      showAlert();
      setProcessing(false);
      //push to array for current entrant.
      //pairingData.accountIds[0]

      let tempE = entrants;

      tempE.push(pairingData.accountIds[0]);
      setEntrants(tempE);
    };

    const recheckBet = async () => {
        console.log('rechecking bet')
  
        let formData = new FormData();
            
  
        for ( var key in submitData ) {
          formData.append(key, submitData[key]);
        }
  
        SubmitRaffleEntry(formData).then((d:any) => {
          if(d.paymentRecived){
            confirmBet(d);
          }else{
            console.log("calling reset! "+ retryCount)
            let ret = retryCount;
            ret++;
            setRetryCount(ret);
            
          }
        })
      }



    let paperStyle:any = {
        p:3,
        position:'relative'
    };


    let theme = createTheme({
      // Theme customization goes here as usual, including tonalOffset and/or
      // contrastThreshold as the augmentColor() function relies on these
    });
    
    

    let highlight = props.gameConfig.highlight;
    


    let fontColour = '#000';
    if(highlight){
      fontColour = crypto.theme.fontColour;
      paperStyle.background = crypto.theme.bg;
      style.color = crypto.theme.fontColour; 
      theme = createTheme(theme, {
        // Custom colors created with augmentColor go here
        palette: {
          primary: theme.palette.augmentColor({
            color: {
              main: crypto.theme.primary,
            }
          }),
        },
      });
        //paperStyle.boxShadow = "0 0 8px 3px rgba(46, 204, 113,.5)";
        //paperStyle.background = "rgba(46, 204, 113,.05)";
    }else{
      theme = createTheme(theme, {
        // Custom colors created with augmentColor go here
        palette: {
          primary: theme.palette.augmentColor({
            color: {
              main: '#000',
            }
          }),
        },
      });
    }

    let userEntered = false;
    if(pairingData){
      if(typeof pairingData.accountIds !== 'undefined'){
        userEntered = props.gameConfig.entrants.includes(pairingData.accountIds[0])
      }
    }
    
    

    React.useEffect(() => {
        if(retryCount !== 0){
          if(retryCount < MAX_RETRIES){
            setTimeout(() =>{
              recheckBet()
            },RETRY_DELAY_MS)
          }else{
            setRetryCount(0);
            setProcessing(false);
            setGameState(0);
          }   
        }
        setEntrants(props.gameConfig.entrants);
      },[retryCount, props.gameConfig.entrants])


    return (
        <>
        <ThemeProvider theme={theme}>
        <Snackbar
            open={snackbarOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={8000}
            onClose={handleAlertClose}
            sx={{mt:6, zIndex:1}}
            message="Note archived">
                <Alert severity={snackbarMessage.severity}>{snackbarMessage.message}</Alert>
                </Snackbar>
        <Box className="scene">

        <Box className={open ? 'flip-card is-flipped': 'flip-card'} >
          <Paper elevation={2} sx={paperStyle} className='flip-card__card flip-card__card--front'>
          <Stack spacing={2} direction="column" alignItems={'center'} sx={{position:'relative', zIndex:10}}>
              
              <Box sx={{textAlign:'center',position:'relative'}}>
                  <CircularProgress variant="determinate" value={percentage} className='largeDisk'/>
                  <Box sx={{position:'absolute', top:25, left:'calc(50% - 22px)'}}><Typography sx={{color:fontColour}}>{entrants.length}/{props.gameConfig.limit}<br /><small>Players</small></Typography></Box>
              </Box>
              <Box>
                  <Typography variant='caption' sx={{color:fontColour}}>Last Winner: - 
                  {/* {props.gameConfig.lastWinner}   */}
                  </Typography>   
              </Box>
              
              <Box>
                <Box sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center', mb:.5 }}>
                  <Avatar sx={{mr:1, height:"32px", width:"32px"}} alt={crypto.symbol} src={crypto.icon} />
                  <Typography variant='h6' sx={{color:fontColour}}><AddComma number={props.gameConfig.bet}></AddComma>  </Typography>
                </Box>
                <Box sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center', mb:2 }}>
                  <Typography variant='caption' sx={{color:fontColour}}>Fee {props.gameConfig.fee}%</Typography>
                </Box>
                  
                  <LoadingButton 
                  size='large' 
                  variant='contained' 
                  onClick={enterRaffle}
                  loading={processing}
                  disabled={processing || !pairingData || userEntered}
                  endIcon={userEntered ? <CheckCircleIcon /> : <LocalActivity />}
                  >{userEntered ? 'Already Entered' : 'Enter Raffle'}</LoadingButton>
                  
              </Box>
              <Box>
                  <Button onClick={handleOpen} variant='contained' size='small' endIcon={<VisibilityIcon />}>Show Entrants</Button>
              </Box>
          </Stack>
          {highlight && <RaffleAnimation image={crypto.icon} />}
          </Paper>
          <Paper elevation={2} sx={paperStyle} className='flip-card__card flip-card__card--back'>
{/*             
            <Button  variant='contained' size='small' endIcon={<VisibilityIcon />}>Show Raffle</Button> */}

            <IconButton onClick={handleClose} color='primary' className='close-btn'>
              <CloseIcon></CloseIcon>
            </IconButton>
              <Box sx={style}>
              <Typography sx={{textAlign:'left'}} variant="h6" component="h2">
                  Raffle: {props.gameId}
              </Typography>
              <List>
                  {entrants.map((entrant, i) => {       
                      return (
                          <ListItem key={'item-'+i} sx={{pl:0}}>
                            <Typography variant='caption'>{entrant}</Typography>
                          </ListItem>
                      ) ;
                  })}
              </List>
              </Box>
          </Paper>
        </Box>
        </Box>
     
        </ThemeProvider>
        </>
    );
};


export default RaffleCard;