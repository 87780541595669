import React, { useState, useContext, useEffect } from "react";
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import CachedIcon from '@mui/icons-material/Cached';
import Typography from "@mui/material/Typography";
import './styles.scss';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { HashconectServiceContext, useHashconnectService } from '../../../services/hashConnect';
import { ProcessGame } from '../../../services/Api';
import { HASHINOCONFIG } from '../../../configs/index';
const Input = styled(MuiInput)`
  width: 42px;
`;

const MAX_RETRIES = 5;
const RETRY_DELAY_MS = 5000; // 5 seconds

const CoinFlip = () => {
  const [retryCount, setRetryCount] = useState(0);
  const [submitData, setFormData] = useState<any>();
  const [gameState, setGameState] = React.useState(0);
  const States = [
      'Idle',
      'Waiting on response from Hashpack',
      'Verifying Transaction',
      'Retrying attempt: ',
      'Coin Flipping',
      'Bet Complete'
  ];
  const hashconnect = useHashconnectService();
  const { pairingData, availableExtension, network, pairingString } = useContext(HashconectServiceContext);
  const currencies = [
    {symbol:'HBAR', image:'/images/hbar.svg', tokenId:'',minBet:10, maxBet:50},
    {symbol:'SAUCEINU', image:'/images/sauceinu.png', tokenId:'',minBet:1000, maxBet:5000},
    {symbol:'SAUCE', image:'/images/sauce.svg', tokenId:'',minBet:20, maxBet:100},
  ];

  const [value, setValue] = React.useState(10);
  const [currency, setCurrency] = React.useState('0');
  let config = currencies[parseInt(currency)];
  const [state, setState] = useState({
      result:"heads-off",
      label:"heads"
  });
  const [reveal, setReveal] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [processing, setProcessing] = useState(false);
  
  const [side, setSide] = React.useState('');


    const handleSliderChange = (event: Event, newValue: number | number[]) => {
      setValue(newValue as number);
      if(reveal){
        setReveal(false)
      }
    };
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value === '' ? 10 : Number(event.target.value));
      if(reveal){
        setReveal(false)
      }
    };
  
    const handleBlur = () => {

      let config = currencies[parseInt(currency)]
      if (value < config.minBet) {
        setValue(config.minBet);
      } else if (value > config.maxBet) {
        setValue(config.maxBet);
      }

      if(reveal){
        setReveal(false)
      }
    };


    const handleChange = (event: SelectChangeEvent) => {
      setCurrency(event.target.value);
      let config = currencies[parseInt(event.target.value)]
      console.log(config);
      setValue(config.minBet);
      if(reveal){
        setReveal(false)
      }
    };

    const submitBetRequest = async () => {
      setState({result:"spin", label:'spin'});
      setProcessing(true);
      setReveal(false);
      setRetryCount(0);
      setGameState(1);
      if(pairingData){
        //console.log('create transatcion');
        await hashconnect.sendTransaction(value, HASHINOCONFIG.cryptos.HBAR).then((transaction:any) => {
          //console.log(transaction);
          if(typeof transaction !== 'undefined'){
            console.info("transaction accepted");
            let data:any = {
              pick:side,
              bet: value+"",
              symbol:currency,
              transactionHash:transaction.transactionHash,
              transactionId:transaction.transactionId,
              nodeId:transaction.nodeId
            };
            
            setGameState(2);

            console.log("timeout request for 5 seconds");
            setFormData(data);
          


              let formData = new FormData();
          

              for ( var key in data ) {
                formData.append(key, data[key]);
              }
              ProcessGame(formData).then((d:any) => {
                    console.log(d);
                    if(d.paymentRecived){
                      setGameState(4);
                      setState({result:d.result, label:d.result});
                      setTimeout(() => {
                        setProcessing(false);
                        setReveal(true);
                        setGameState(5);
                      },3100)
                    }else{
                      console.log("calling reset!")
                      setRetryCount(1); 
                      setGameState(3);
                      //recheckBet(formData);
                    }
                  })

          }else{
            //setProcessing(false);
            
            console.log("failed transaction");
            setGameState(0);
            setErrorStatus("Transaction rejected in Hashpack");
            setState({result:side+"--off", label:side});
            setProcessing(false);
            setReveal(true);
          }
        });
        
      }
    }

    const recheckBet = async () => {
      console.log('rechecking bet')

      let formData = new FormData();
          

      for ( var key in submitData ) {
        formData.append(key, submitData[key]);
      }

      ProcessGame(formData).then((d:any) => {
        console.log(d);
        if(d.paymentRecived){
          setState({result:d.result, label:d.result});
          setTimeout(() => {
            setProcessing(false);
            setReveal(true);
          },3100)
        }else{
          console.log("calling reset! "+ retryCount)
          let ret = retryCount;
          ret++;
          setRetryCount(ret);
          
        }
      })
    }

    let winnings = value * 2;
    let fee  = .025;

    let finalAmount = winnings - (winnings * fee);
    let finalFee = winnings - finalAmount;
    

    useEffect(() => {
      console.log(retryCount)
      if(retryCount !== 0){
        if(retryCount < MAX_RETRIES){
          setTimeout(() =>{
            console.log("rechecking");
            recheckBet()
          },RETRY_DELAY_MS)
        }else{
          setRetryCount(0);
          setState({result:side+"--off", label:side});
          setProcessing(false);
          setGameState(0);
          setErrorStatus("Failed to confirm transaction");
          setReveal(true);
        }
        
        
      }else{
        console.log('init');
      }
    },[retryCount])

    return (
        <>
        
        <Box sx={{ width: '100%' }}>
          <Stack spacing={2}>
        <Box
        sx={{textTransform:'capitalize'}}
        className={`reveal-text ${reveal ? "revealed" : ""}`}
      >
      
        {errorStatus.length > 0 ? (
          <Alert severity="error">{errorStatus}</Alert>
        ) : (
          <>
            {state.result == side ? (
              <Alert severity="success">You <strong>WON</strong> {config.symbol} {finalAmount}</Alert>
            ) : (
              <Alert severity="info">You picked {side} and it came up {state.label}</Alert>
            )}
          </>
        )}
      </Box>
      <Box sx={{pb:2}}>
        <Box id="coin" className={state.result} key={'coin'}>
          <Box className="side-a">{/* heads */}</Box>
          <Box className="side-b">{/* tails */}</Box>
        </Box>
      </Box>
      <ButtonGroup 
      variant="contained" 
      aria-label="outlined primary button group" 
      sx={{display:'flex', flexDirection:'row', alignItems:'stretch', my:2}}
      >
        <Button 
          sx={{flex:1}}
          disabled={processing}
          startIcon={side === 'heads' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon /> }
          size="large"
          onClick={() => {
            setSide('heads');
            setState({result:'heads--no-animation', label:'heads'});
            setReveal(false);
          }}
        >Heads</Button>
        <Button 
          sx={{flex:1}}
          disabled={processing}
          endIcon={side === 'tails' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon /> }
          size="large"
          onClick={() => {
            setSide('tails');
            setState({result:'tails--no-animation', label:'tails'});
            setReveal(false);
          }}
        >Tails</Button>
      </ButtonGroup>

      <FormControl>
        <InputLabel id="demo-simple-select-autowidth-label">Currency</InputLabel>
        <Select
          disabled={processing}
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={currency}
          onChange={handleChange}
          label="Currency"
        >
          

          {currencies.map((currency:any, index:number) => 
              <MenuItem key={"currency-"+index} value={index+""} disabled={index != 0}>  
                <Box sx={{display:'flex'}}>
                  <Avatar sx={{mr:2, height:"32px", width:"32px"}} alt={currency.symbol} src={currency.image} /> 
                  <Typography sx={{marginTop:"5px"}}>{currency.symbol}</Typography>
                </Box>
              </MenuItem>
          )}
          
        </Select>
      </FormControl>
    <Box>
      <Typography id="input-slider" gutterBottom>
        Amount
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Avatar  alt={config.symbol} src={config.image} />
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={config.minBet}
            max={config.maxBet}
            disabled={processing}
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={processing}
            sx={{minWidth:'70px', textAlign:'center'}}
            inputProps={{
              min:config.minBet,
              max: config.maxBet,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
      
    </Box>
      <Box sx={{display:'flex', flexDirection:'row', justifyContent:'end', width:"100%"}}>
        <Box><Chip size="small" label={'Potential return: ' + finalAmount + ' ' +config.symbol} /></Box>
        <Box sx={{marginLeft:'auto'}}>
          <Chip size="small" label={'Fee: 2.5%'} />
        </Box>
      </Box>
      <Box sx={{display:'flex', flexDirection:'row', justifyContent:'start', width:"100%"}}>   
      {gameState == 3 && retryCount > 1 && (
        <Chip size="small" label={States[gameState] + ' '+ retryCount} />
      )}
      </Box>
       
        <LoadingButton
        size="large"
        loading={processing}
        disabled={side == '' || processing || !pairingData}
        loadingPosition="end"
        onClick={submitBetRequest}
        endIcon={<CachedIcon />}
        variant="contained"
      >
        {processing ? (
          <>
          Flipping
          </>
        ) : (
          <>
          Flip Coin
          </>
        )}
        
      </LoadingButton>
      </Stack>
    </Box>
        </>
    );
};

export default CoinFlip;