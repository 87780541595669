import * as React from 'react';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import {Helmet} from "react-helmet";
const Error404Page = () => {


    return ( 
        <>
        <Helmet>
            <title>Hashino Casino | Page not found</title>
            <meta name="description" content={`Hashino Casino`} />
        </Helmet>
        <Typography>Page not found</Typography>
        <Link component={ReactRouterLink} to="/">
            Go to Home
        </Link>
        </>
    );
};



export default Error404Page;