import React, { useState, useEffect } from 'react';
import GameCard from '../components/ui/GameCard';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import {Helmet} from "react-helmet";
import  RaffleCard  from '../components/games/raffle/RaffleCard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as ReactRouterLink } from 'react-router-dom';
import { GetRaffles } from '../services/Api';
import RaffleSkeleton from '../components/ui/RaffleSkeleton';
import HowItWorks from '../components/games/raffle/HowItWorks';
import GambleAwareBanner from '../components/ui/GambleAwareBanner';
const RafflePage = () => {

    const [raffles, setRaffles] = useState([]);


    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" component={ReactRouterLink} to="/">Home</Link>,
        <Link
        underline="hover"
        key="2"
        color="inherit"
        component={ReactRouterLink} 
        to="/games"
        >
            Games
        </Link>,
        <Typography key="3" color="text.primary">
            Raffle
        </Typography>,
    ];


    useEffect(() => {
        GetRaffles().then((d:any) => {
            console.log(d);
            if(d.length > 0){
                setRaffles(d);
            }
        })
    }, [setRaffles]);

    return ( 
        <>
        <Helmet>
            <title>Hashino Casino | Raffle</title>
            <meta name="description" content={`Hashino Casino`} />
        </Helmet>
        <Container maxWidth="md">
        <Box sx={{ flexGrow: 1, mt:4, mb:2 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
        </Breadcrumbs>
        </Box>
        <Box sx={{ flexGrow: 1, mt:10, mb:4 }}>

            <Grid container spacing={2}>
                {raffles.length > 0 ? (
                    raffles.map((item:any, index) => (
                        <Grid xs={12} sm={6} md={4} lg={4} key={'rcr-'+index}>
                            <RaffleCard key={'rc-'+index} gameId={item.raffle_id} gameConfig={{entrants:item.entrants,lastWinner:'0.0.12456',players:item.entrants.length, limit:item.max_number_of_entrants, bet:item.entry_cost, currency:item.currency, fee:item.entry_fee, highlight:item.highlight_raffle}} />
                        </Grid>
                  ))
                ) : (
                    <RaffleSkeleton></RaffleSkeleton>
                )}
                
            </Grid>
        </Box>
        <HowItWorks />
        
        </Container>
        <GambleAwareBanner />
        </>
    );
};



export default RafflePage;