import * as React from 'react';
import GameCard from '../components/ui/GameCard';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import {Helmet} from "react-helmet";
const AboutPage = () => {


    return ( 
        <>
        <Helmet>
            <title>Hashino Casino | About us</title>
            <meta name="description" content={`Hashino Casino`} />
        </Helmet>
        <Container maxWidth="md">
        <Box sx={{ flexGrow: 1, mt:10 }}>

        <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={12} sx={{mb:3}}>
                    <Typography variant='h2' gutterBottom sx={{fontFamily:"'Krona One', sans-serif", fontSize:'2.7em'}}>About Hashino Casino</Typography>
                    <Typography>Hashino Casino development began around early September 2023 and from there we have aimed to develop safe, secure and fun games on the Hedera Network.</Typography>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} sx={{mb:3}}>
                    <Typography variant='h5' gutterBottom>Why dont we use Smart Contracts?</Typography>
                    <Typography>Security and reduced fees. We've witnessed external contractors submit malicous code that allowed them to drain wallets through allowances. With this in mind we have taken the descion to use transfers and a payout bot to secure both ourselves and our customers.</Typography>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12} sx={{mb:3}}>
                    <Typography variant='h5' gutterBottom>How does it work?</Typography>
                    <Typography>When users place a bet, we will store a copy of that transaction. We then use a bot that checks for winning bets every 10 seconds which will then be automaticly paid out.</Typography>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12} sx={{mb:3}}>
                    <Typography variant='h5' gutterBottom>How can I get a token or coin added?</Typography>
                    <Typography>Hop into our <a href='https://discord.gg/nZekJ7hyce' target='_blank'>discord</a>, we are open to discusions about listing more.</Typography>
                </Grid>

            </Grid>
        </Box>
        </Container>
        </>
    );
};



export default AboutPage;