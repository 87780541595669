import * as React from 'react';
import GameCard from '../components/ui/GameCard';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import BannerSlider from  '../components/ui/Slider';
import Container from '@mui/material/Container';
import GambleAwareBanner from '../components/ui/GambleAwareBanner';
import PromoBanner from '../components/ui/PromoBanner';
import {Helmet} from "react-helmet";
const HomePage = () => {


    return ( 
        <>

        <Helmet>
            <title>Hashino Casino</title>
            <meta name="description" content={`Hashino Casino`} />
        </Helmet>


        <Box sx={{ mt:-2}}>
        <BannerSlider />
        </Box>
        
        <Container >
        <Box sx={{ flexGrow: 1, mt:10, mb:10 }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={6} md={4} lg={3}>
                    <GameCard url='/games/coin-flip' buttonLabel='Play Coin Flip' infoChip='Play Coin Flip Now!' name="Hashino - Coin Flip" background="/images/card-bg.jpg" mainImage="/images/coinflip.gif"/>
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={3}>
                    <GameCard url='/games/raffle' buttonLabel='Play Raffle' infoChip='Raffle Coming Soon' background="/images/card-bg.jpg" mainImage="/images/raffle.gif" name="Hashino - Coin Flip"/>
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={3}>
                    <GameCard url='/games/coin-flip' buttonLabel='Play Blackjack' infoChip='Blackjack Coming Soon' disableLink={true} background="/images/card-bg.jpg" mainImage="/images/blackjack.gif" name="Hashino - Coin Flip"/>
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={3}>
                    <GameCard url='/games/coin-flip' buttonLabel='Play Coin Flip' infoChip='More Coming Soon' disableLink={true} background="/images/card-bg.jpg" mainImage="/images/next.gif" name="Hashino - Coin Flip"/>

                </Grid>
            </Grid>
        </Box>
        </Container>
        <GambleAwareBanner />
        </>
    );
};



export default HomePage;