import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import {ReactComponent as  Logo } from './logo.svg';
import ConnectButton from '../ConnectButton';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import './header.scss';
import { useNavigate } from "react-router-dom";




interface HeaderProps {
    menuCallback:Function;
}

const Header = (props:HeaderProps) => {
    const navigate = useNavigate();
    
    const goToHome = () => {
        navigate('/')
    }
    return (
        <>
            {/* Mobile Bar */}
            <Toolbar sx={{display: { md: 'none', xs: 'flex' }}}>
                <Box sx={{display:'flex',justifyContent:'center'}}>
                    <Logo className='logo'/>
                </Box>
                <Box sx={{marginLeft:'auto', minWidth:'95px'}}>
                    <ConnectButton />
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            props.menuCallback()
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    
                </Box>
            </Toolbar>

            {/* Desktop Bar */}
            <Toolbar sx={{justifyContent:'center',display: { md: 'flex', xs: 'none' }}}>
                <Box sx={{marginRight:'auto', minWidth:'95px'}}>
                <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => {
                                props.menuCallback()
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                </Box>
                <Box sx={{margin:'auto', display:'flex',justifyContent:'center', cursor:'pointer'}} >
                    <Logo className='logo' onClick={goToHome}/>
                </Box>
                <Box sx={{marginLeft:'auto', minWidth:'70px'}}>
                    <ConnectButton />
                </Box>
            </Toolbar>

            
         
        </>
    );
};


export default Header;