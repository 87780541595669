import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import './GameCard.scss';
interface GameCardProps {
    url:string,
    name:string,
    buttonLabel:string,
    infoChip?:string,
    disableLink?:boolean,
    mainImage?:string,
    background?:string
};

const GameCard = (props:GameCardProps) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const navigate = useNavigate();
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleMouseClick = () => {
        if(!props.disableLink){
            navigate(props.url)
        }    
    };


    return (
        <Card
            sx={{
                height: 240,
                pointer:'cursor',
                position: 'relative',
                overflow: 'hidden',
            }}
            className='game-card'
            onClick={handleMouseClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            
        >
            {props.infoChip && (
                <Chip label={props.infoChip} color='info' size="small" sx={{position:'absolute', right:"10px", top:"10px", zIndex:10}}/>

            )}
            {/* Extra image in front */}

            {props.mainImage && (
                <div
                className='game-card__inner'
                    style={{
                        position: 'absolute',
                        top: "25px",
                        left: "50%",
                        marginLeft:"-90px",
                        pointerEvents:'none', 
                        zIndex:7
                    }}
                >
                    <img
                        src={props.mainImage} // Replace with your actual image URL
                        alt={props.name}
                    />
                </div>
            )}
            
            {props.background ? (
            <CardMedia
                sx={{ height: 240 }}
                image={props.background} // Replace with your actual image URL
                title={props.name}
            />
            ) : (
            <CardMedia
                sx={{ height: 240 }}
                image="https://placehold.co/340x240/222222/444" // Replace with your actual image URL
                title={props.name}
            />
            )}
            {!props.disableLink && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: isHovered ? 0 : -100,
                        left: 0,
                        right: 0,
                        py:3,
                        transition: 'bottom 0.3s ease-in-out',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex:11
                    }}
                >
                    <Button variant="contained">{props.buttonLabel}</Button>
                </Box>
            )}
        </Card>
    );
};

export default GameCard;
