import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';

const RaffleSkeleton = () => {
    return (
        <>
            <Grid xs={12} sm={6} md={4} lg={4} sx={{mb:3}}>
                <Skeleton variant="rectangular" width={"100%"} height={348} />
            </Grid>
            <Grid xs={12} sm={6} md={4} lg={4} sx={{mb:3}}>
                <Skeleton variant="rectangular" width={"100%"} height={348} />
            </Grid>
            <Grid xs={12} sm={6} md={4} lg={4} sx={{mb:3}}>
                <Skeleton variant="rectangular" width={"100%"} height={348} />
            </Grid>

        </>
    )
};


export default RaffleSkeleton;