import React from 'react';
import Box from '@mui/material/Box';

const RaffleAnimation = (props:{image:string}) => {

    return (
        <>
        <Box className="bubbles">
        <Box className="bubble" sx={{backgroundImage:'url('+props.image+')'}}></Box>
        <Box className="bubble" sx={{backgroundImage:'url('+props.image+')'}}></Box>
        <Box className="bubble" sx={{backgroundImage:'url('+props.image+')'}}></Box>
        <Box className="bubble" sx={{backgroundImage:'url('+props.image+')'}}></Box>
        <Box className="bubble" sx={{backgroundImage:'url('+props.image+')'}}></Box>
        <Box className="bubble" sx={{backgroundImage:'url('+props.image+')'}}></Box>
        <Box className="bubble" sx={{backgroundImage:'url('+props.image+')'}}></Box>
        <Box className="bubble" sx={{backgroundImage:'url('+props.image+')'}}></Box>
        <Box className="bubble" sx={{backgroundImage:'url('+props.image+')'}}></Box>


        
    </Box>
        </>
    );
}

export default RaffleAnimation;