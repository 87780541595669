import * as React from 'react';
import { Route, Routes } from "react-router-dom";
import CoinflipPage from "./pages/Coinflip";
import HomePage from './pages/Home';
import GamesPage from './pages/Games';
import Error404Page from './pages/404Page';
import AboutPage from './pages/AboutPage';
import RafflePage from './pages/RafflePage';
const Router = () => {

    return (
        <Routes>
            <Route path="*" element={<Error404Page />} />

            <Route path="/" element={<HomePage />} />
            <Route path="/games/coin-flip" element={<CoinflipPage />} />
            <Route path="/games" element={<GamesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/games/raffle" element={<RafflePage />} />
            
            {/* <Route path="/login" element={<DebugPage />} />
            <Route path="/debug" element={<DebugPage />} />
            
            <Route path="/kitchen-sink" element={<KitchenSinkPage />} />
            <Route path="/wallet/:accountId" element={<WalletView />} />
            <Route element={<LoggedIn />}>
                
                <Route path="/portfolio" element={<PortfolioPage />} />

            </Route> */}
        </Routes>
    );
}

export default Router;
