import * as React from 'react';
import Link from '@mui/material/Link';
import { HashconnectAPIProvider } from './services/hashConnect';
import { HASHPACKCONFIG } from './configs/index';
import  Router  from './Router';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Header from './components/ui/header/Header';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as ReactRouterLink } from 'react-router-dom';
import {ReactComponent as  Logo } from './components/ui/header/logo.svg';
import HouseIcon from '@mui/icons-material/House';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import TollIcon from '@mui/icons-material/Toll';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/Mail';
import { useNavigate } from "react-router-dom";
import './App.scss';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import ScrollToTop from './utilities/ScrollToTop';
const Footer = () => {
  const openInNewTab = (url:string) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) => '#1a1a1a',
        py: 6,
        px:'16px'
      }}
    >
      <Container maxWidth="lg" sx={{color:'white'}}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Logo style={{marginBottom:8}}/>
            <Typography variant="h6" color="white" gutterBottom sx={{fontFamily:"'Krona One', sans-serif"}}>
              About Us
            </Typography>
            <Typography variant="body2" color="white">
              The Hedera Network based Casino
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* <Typography variant="h6" color="white" sx={{mt:5,fontFamily:"'Krona One', sans-serif"}} gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="inherit">
              123 Main Street, Anytown, USA
            </Typography>
            <Typography variant="body2" color="inherit">
              Email: info@example.com
            </Typography>
            <Typography variant="body2" color="inherit">
              Phone: +1 234 567 8901
            </Typography> */}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="white" gutterBottom sx={{mt:5,fontFamily:"'Krona One', sans-serif"}}>
              Follow Us
            </Typography>
            <Link
              color="inherit"
              sx={{ cursor:'pointer' }}
              onClick={() => openInNewTab("https://discord.gg/nZekJ7hyce")}>
                <Box className="discord-icon"></Box>
            </Link>
            {/* <Link
              color="inherit"
              sx={{ pl: 1, pr: 1, cursor:'pointer' }}
              onClick={() => openInNewTab("https://www.instagram.com/hashinocasino")}
            >
              <Instagram />
            </Link> */}
            <Link color="inherit"
                  sx={{ cursor:'pointer' }}
                  onClick={() => openInNewTab("https://www.twitter.com/hashinocasino")}>
              <Twitter />
            </Link>
            
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="inherit" align="center">
            
            <Link color="inherit" href="https://hashino.casino/">
              Hashino Casino 
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
            <br />
            <small>{'Release Version ' + HASHPACKCONFIG.version}</small>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
  // return (
  //   <Typography variant="body2" color="inherit" align="center">
  //     {'Copyright © '}
  //     <Link color="inherit" href="#">
  //       Hashino
  //     </Link>{' '}
  //     {new Date().getFullYear()}.
  //     <br />
  //     {'Version ' + HASHPACKCONFIG.version}
  //   </Typography>
  // );
}

interface HashNetwork {
  network: "testnet" | "mainnet" | "previewnet";
}

interface HeaderProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

const HideOnScroll = (props: HeaderProps) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function App() {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const top_urls = [
    {url:'/',label:'Home', icon:<HouseIcon sx={{color:'white'}}/>},
    {url:'/games',label:'Games', icon:<VideogameAssetIcon sx={{color:'white'}} />},
    {url:'/games/coin-flip',label:'Coin Flip', icon:<TollIcon sx={{color:'white'}}/>}
  ];

  const bottom_urls = [
    {url:'/about',label:'About', icon:<InfoIcon sx={{color:'white'}}/>},
    {url:'/support',label:'Support', icon:<ContactSupportIcon sx={{color:'white'}}/>},
    // {url:'/contact',label:'Contact', icon:<MailIcon sx={{color:'white'}}/>},
    
  ];

  const toggleDrawer =(anchor: Anchor, open: boolean) => {
      setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor, menuCallback:Function) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
    >
      <Box sx={{p:3, pb:2}}>
        <Logo style={{maxWidth:"100%"}}/>
      </Box>
      <Divider sx={{background:'rgba(255,255,255,.1)'}}/>
      <List>
        {top_urls.map((text, index) => (
          <ListItem key={"menu-item-"+index} disablePadding>
            <ListItemButton onClick={() =>{
              navigate(text.url)
              menuCallback();
            }}>
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={text.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{background:'rgba(255,255,255,.1)'}}/>
      <List>
        {bottom_urls.map((text, index) => (
          <ListItem key={"menu-item-b-"+index} disablePadding>
            <ListItemButton onClick={() =>{
              if(text.url !== '/support'){
                navigate(text.url)
              }else{
                window.open('https://discord.gg/A6yx4mgP2S', "_blank", "noreferrer");
              }
              
              menuCallback();
            }}>
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={text.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  let system:HashNetwork = {
    network:'testnet'
  };
  if(HASHPACKCONFIG.network == 'mainnet'){
    system.network = 'mainnet';
  }

  let props = {};
  return (
    <HashconnectAPIProvider network={system.network}>
      <CssBaseline />
      <ScrollToTop />
            <HideOnScroll {...props}>
                
                <AppBar position='sticky'>
                  <Header menuCallback={() => {
                    console.log('toggle draw');
                    toggleDrawer('left', true);
                  }}/>
                
                </AppBar>
            </HideOnScroll>
            
                <Box sx={{ mt: 2, minHeight:"90vh" }}>
                  <Router />
               
                </Box>

                <Drawer
                  PaperProps={{
                    sx: {
                      backgroundColor: "#131313",
                      color: "white"
                    }
                  }}
                  anchor={'left'}
                  open={state['left']}
                  onClose={() => {
                    toggleDrawer('left', false)
                  }}
                >
            {list('left', () => {
              toggleDrawer('left', false)
            })}
          </Drawer>
          <Footer />
            
      {/* <Router /> */}
    </HashconnectAPIProvider>
  );
}
