import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import HashinoCard from '../../ui/HashinoCard/HashinoCard';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaidIcon from '@mui/icons-material/Paid';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
const HowItWorks = () => {
    const minHeight = '365px';
    return (
        <>
        <Typography variant='h4' sx={{textAlign:'center',mt:15, mb:4, fontFamily:"'Krona One', sans-serif"}}>How does it work?</Typography>
            <Grid container spacing={2} sx={{mb:20}}>
                <Grid xs={12} sm={6} md={4} lg={4}>
                    <HashinoCard 
                        minHeight={minHeight}
                        icon={<MonetizationOnIcon />} 
                        title={'Select a side'} 
                        description={'First connect your wallet and then simply select which side of the coin to play, the token you wish to use and the amount you wish to bet.'}
                    ></HashinoCard>                
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={4}>
                    <HashinoCard 
                        minHeight={minHeight}
                        icon={<HourglassFullIcon />} 
                        title={'Await Results'} 
                        description={'Once the transfer is complete, a script will be called that will virtually flip a coin. It does this based on the transaction consensus time. We shuffle the numbers and if the number comes up even, it is heads and the user has selected heads then they win.'}
                    ></HashinoCard>                
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={4}>
                    <HashinoCard 
                        minHeight={minHeight}
                        icon={<PaidIcon />} 
                        title={'Winner Payout'} 
                        description={'If a user selects the correct side then a crypto transfer is automaticly made. This is usually done within 30 seconds of you seeing the winners notifcation'}
                    ></HashinoCard>                
                </Grid>

            </Grid>

            
        </>
    );
};

export default HowItWorks;