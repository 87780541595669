import React, { useState  } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from '@mui/system';
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
const SlideContainer = styled('div')({
  position: 'relative',
});

const Title = styled('h2')({
  fontSize: '3em',
  fontWeight: 'bold',
  margin:0,
  fontFamily:"'Krona One', sans-serif"
});


const Subtitle = styled('h5')({
  fontSize: '1.45em',
  marginBottom:"20px",
  fontWeight:'500',
  textTransform:'uppercase',
  marginTop:0
});



const BannerSlider = () => {
  const navigate = useNavigate();
    const settings = {
      dots: true,
      infinite: true,
      arrows:true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleBeforeChange = (current:any, next:any) => {
      // Add exit animations here for the current slide (if needed)
      // You can use CSS classes to apply exit animations
    };
  
    const handleAfterChange = (current:any) => {
      setCurrentSlide(current);
      // Add entry animations here for the current slide (if needed)
      // You can use CSS classes to apply entry animations
    };

    const bgPath = 'url('+window.location.origin +"/images/bg1.jpg)";
    const bgPath2 = 'url('+window.location.origin +"/images/coinflip.jpg)";
    return (
      <div>
        <Slider 
        {...settings}
        className="hashino-slider"
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        >
          <div className={currentSlide === 0 ? 'active-slide' : ''}>
            <Box sx={{backgroundImage:bgPath,zIndex:3, position:'absolute', top:0, left:0, right:0, bottom:0, backgroundPosition:'center', backgroundSize:'cover'}} />
            <Title className="slide-title">Welcome to HASHINO!</Title>
            <Subtitle className="slide-subtitle">The Hedera based Casino</Subtitle>
            {/* <Button className="slide-button" variant="contained" size="large">View More</Button> */}
          </div>
          <div className={currentSlide === 1 ? 'active-slide' : ''}>
            <Box sx={{backgroundImage:bgPath2,zIndex:3, position:'absolute', top:0, left:0, right:0, bottom:0, backgroundPosition:'center', backgroundSize:'cover'}} />

            <Title className="slide-title">Coin Flip now avalible!</Title>
            <Subtitle className="slide-subtitle">More tokens coming soon!</Subtitle>
            <Button className="slide-button" variant="contained" size="large" onClick={() => {
              navigate('/games/coin-flip')
            }}>Play now!</Button>
          </div>
          {/* <div className={currentSlide === 2 ? 'active-slide' : ''}>
            <Title className="slide-title">Slide 3 Title</Title>
            <Subtitle className="slide-subtitle">Slide 3 Subtitle</Subtitle>
            <Button className="slide-button" variant="contained" size="large">View More</Button>
          </div> */}
          
        </Slider>
      </div>
    );
}

export default BannerSlider;