import React from "react";
const AddComma = (prop:{number:number}) => {
    let number =  prop.number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return (
        <>
            {number}
        </>
    );
};


export default AddComma;

