import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import HashinoCard from '../../ui/HashinoCard/HashinoCard';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CasinoIcon from '@mui/icons-material/Casino';
import PaidIcon from '@mui/icons-material/Paid';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
const HowItWorks = () => {
    const minHeight = '300px';
    return (
        <>
        <Typography variant='h4' sx={{textAlign:'center',mt:15, mb:4, fontFamily:"'Krona One', sans-serif"}}>How does it work?</Typography>
            <Grid container spacing={2} sx={{mb:20}}>
                <Grid xs={12} sm={6} md={4} lg={4}>
                    <HashinoCard 
                        minHeight={minHeight}
                        icon={<LocalActivityIcon />} 
                        title={'Enter a Raffle'} 
                        description={'First connect your wallet and then select the raffle you want to enter. A crypto transfer will be requested, which on approval will register an entry on our game service.'}
                    ></HashinoCard>                
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={4}>
                    <HashinoCard 
                        minHeight={minHeight}
                        icon={<HourglassFullIcon />} 
                        title={'Await Results'} 
                        description={'Once a raffle is full, a script will run that will select a winner out of the entrants. To do this, a list of account IDs will be shuffled and then a random winner is selected.'}
                    ></HashinoCard>                
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={4}>
                    <HashinoCard 
                        minHeight={minHeight}
                        icon={<PaidIcon />} 
                        title={'Winner Payout'} 
                        description={'Once a winner has been chosen, the payout should be completed automaticly within a few minutes.'}
                    ></HashinoCard>                
                </Grid>

            </Grid>

            
        </>
    );
};

export default HowItWorks;