import * as React from 'react';
import GameCard from '../components/ui/GameCard';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as ReactRouterLink } from 'react-router-dom';
import {Helmet} from "react-helmet";
const GamesPage = () => {
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" component={ReactRouterLink} to="/">Home</Link>,
        <Typography key="2" color="text.primary">
            Games
        </Typography>
    ];

    return ( 
        <>
        <Helmet>
            <title>Hashino Casino | Games</title>
            <meta name="description" content={`Hashino Casino`} />
        </Helmet>
        <Container>
        <Box sx={{ flexGrow: 1, mt:4 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{mb:3}}>
            {breadcrumbs}
        </Breadcrumbs>
        <Grid container spacing={2}>
                <Grid xs={12} sm={6} md={4} lg={3}>
                    <GameCard url='/games/coin-flip' buttonLabel='Play Coin Flip' infoChip='Play Coin Flip Now!' name="Hashino - Coin Flip" background="/images/card-bg.jpg" mainImage="/images/coinflip.gif"/>
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={3}>
                    <GameCard url='/games/raffle' buttonLabel='Play Raffle' infoChip='Raffle Coming Soon' background="/images/card-bg.jpg" mainImage="/images/raffle.gif" name="Hashino - Coin Flip"/>
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={3}>
                    <GameCard url='/games/coin-flip' buttonLabel='Play Blackjack' infoChip='Blackjack Coming Soon' disableLink={true} background="/images/card-bg.jpg" mainImage="/images/blackjack.gif" name="Hashino - Coin Flip"/>
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={3}>
                    <GameCard url='/games/coin-flip' buttonLabel='Play Coin Flip' infoChip='More Coming Soon' disableLink={true} background="/images/card-bg.jpg" mainImage="/images/next.gif" name="Hashino - Coin Flip"/>

                </Grid>
            </Grid>
        </Box>
        </Container>
        </>
    );
};



export default GamesPage;