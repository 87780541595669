import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CoinFlip from '../components/games/coin-flip/CoinFlip';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as ReactRouterLink } from 'react-router-dom';
import GambleAwareBanner from '../components/ui/GambleAwareBanner';
import PromoBanner from '../components/ui/PromoBanner';
import {Helmet} from "react-helmet";
import HowItWorks from '../components/games/coin-flip/HowItWorks';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }


const CoinflipPage = () => {

    

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" component={ReactRouterLink} to="/">Home</Link>,
        <Link
        underline="hover"
        key="2"
        color="inherit"
        component={ReactRouterLink} 
        to="/games"
        >
            Games
        </Link>,
        <Typography key="3" color="text.primary">
            Coin Flip
        </Typography>,
    ];

    return (
        <>
        <Helmet>
            <title>Hashino Casino | Coin Flip</title>
            <meta name="description" content={`Hashino Casino`} />
        </Helmet>
        <Container>
        <Box sx={{ flexGrow: 1, mt:4, mb:2 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
        </Breadcrumbs>
        </Box>
        </Container>
        
        <Container maxWidth="sm" sx={{mb:20}}>
        
                {/* <Typography variant="h4" component="h1" gutterBottom>
                    Coinflip Prototype
                </Typography> */}

                <CoinFlip />
        </Container>
        <Container maxWidth="md">
            <HowItWorks />
        </Container>
        
        <GambleAwareBanner />
        
      </>
    )
};


export default CoinflipPage;