import React, { useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

import HASHPACKLOGO from '../../assets/hashpack-light.svg';
// import  initWallet  from "../../services/hashConnect";
import { HashconectServiceContext, useHashconnectService } from '../../services/hashConnect';
const style = {
  position: 'absolute' as 'absolute',
  top: '300px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:"90%",
  maxWidth: "450px",
  bgcolor: 'rgba(35,35,35,1)',
  border: '1px solid rgba(0,0,0,.2)',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const ConnectButton = () => {
  //const data = useContext(HashconectServiceContext);
  const { pairingData, availableExtension, network, pairingString } = useContext(HashconectServiceContext);
  const hashpack = useHashconnectService();
  const [open, setOpen] = React.useState(false);
  //const { connectToExtension, disconnect, pairingData, availableExtension, network, pairingString } = useContext(HashconectServiceContext);
  const clickConnectButton = async () =>{
    console.log(pairingData);
    if(pairingData){
      hashpack.disconnect();
    }else{
      console.log(hashpack);
      hashpack.connectToExtension().then(() => {
        handleClose();
      });

    }
  }

  
  const handleOpen = () => {
    
    if(pairingData){
      hashpack.disconnect();
    }else{
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() =>{
    console.log(availableExtension);
  },[availableExtension])
  return (
    <>
      <Button
        // endIcon={pairingData ? <PowerOffIcon /> : <PowerIcon />}
        onClick={() =>{
          handleOpen()
          //clickConnectButton();
        }}
        sx={{ 
          fontSize: '0.8em',
          color:'#fff'
          
         }}
      >
        
        {pairingData ? "Disconnect" : "Connect"}
      </Button>

      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant='h4' sx={{color:'white', fontWeight:'bold', mb:3, mt:1, textAlign:'center'}}>Connect to Hashino</Typography>
          <Stack spacing={2}>
          <Button variant="contained" 
          onClick={() => {
            clickConnectButton();
          }}>
            <div className='hashino-icons hashpack-icon'></div>
            <Typography>Connect with HASHPACK</Typography>
          </Button>

          <Button variant="contained" onClick={() => {
            alert('Coming soon');
          }}>
            <div className='hashino-icons blade-icon'></div>
            <Typography>Blade Wallet coming soon</Typography>
          </Button>
          </Stack>
            {/* <Typography id="transition-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}
          </Box>
        </Fade>
      </Modal>
    </>
  )
};

export default ConnectButton;